.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140vh;
  background-color: #f0f2f5;
}

.restaurant-registration-form {
  width: 100%;
  max-width: 600px;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.restaurant-registration-form .form-item {
  /* margin-bottom: 20px; */
  padding: 10px;
}

.restaurant-registration-form .ant-form-item-label > label {
  color: #333;
  font-weight: bold;
}

.restaurant-registration-form .ant-input,
.restaurant-registration-form .ant-select-selector {
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  transition: all 0.3s;
}

.restaurant-registration-form .ant-input:focus,
.restaurant-registration-form .ant-select-selector:focus,
.restaurant-registration-form .ant-input-focused,
.restaurant-registration-form .ant-select-focused {
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.restaurant-registration-form .ant-btn-primary {
  width: 100%;
  background-color: #1a73e8;
  border-color: #1a73e8;
  border-radius: 4px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .restaurant-registration-form {
    padding: 20px;
  }
}
