.profile-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Adjust the ratio as needed */
  gap: 20px;
  /* align-items: center; Center items vertically */
}

/* .profile-image {
  width: fit-content;
  height: 100%;
} */

.profile-details {
  display: flex;
  flex-direction: column;
}
/* .profile-details img {
  height: 400px;
} */
@media screen and (max-width: 795px) {
  .profile-grid {
    display: grid;
    grid-template-columns: 1fr; /* Adjust the ratio as needed */
    gap: 20px;
    /* align-items: center; Center items vertically */
  }
}
