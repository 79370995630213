/* Restaurant Page Container */
.restaurant-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;
}

.restaurant-page-overlay {
  background-color: rgba(0, 0, 0, 0.6); /* dark overlay */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Content Wrapper */
.restaurant-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  max-width: 1200px;
  padding: 20px;
}

@media (min-width: 768px) {
  .restaurant-content-wrapper {
    flex-direction: row;
  }
}

/* Left Section */
.restaurant-left {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .restaurant-left {
    text-align: left;
    margin-right: 20px;
    padding-right: 20px;
  }
}

.restaurant-left h1 {
  font-size: 28px;
  font-weight: bold;
}

.restaurant-left h2 {
  font-size: 22px;
  margin-top: 10px;
}

.restaurant-left p {
  font-size: 16px;
  margin-top: 10px;
}

/* Right Section */
.restaurant-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-card {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  color: black;
  text-align: center;
}

.right-card h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.right-card ul {
  list-style: none;
  padding: 0;
  font-size: 16px;
  line-height: 1.8;
}

.right-card ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.right-card ul li .icon {
  font-size: 20px;
  margin-right: 10px;
}

.right-card .powered-by {
  margin: 20px 0;
  font-style: italic;
  color: #333;
}

.price-section {
  margin-top: 20px;
}

.price-section h4 {
  font-size: 22px;
  color: #ff6200;
}

.order-now {
  background-color: #ff6200;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.order-now:hover {
  background-color: #ff5200;
}

/* Fixed Telegram Chat Button */
.telegram-chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0088cc;
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
  z-index: 999;
  transition: background-color 0.3s;
}

.telegram-chat-button:hover {
  background-color: #0077aa;
}

.telegram-icon {
  margin-right: 10px;
  font-size: 20px;
}
