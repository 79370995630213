.custom-phone-input input {
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.custom-phone-input:focus-within {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.custom-phone-input .PhoneInputCountry {
  padding: 10px;
  margin: 0;
}

.custom-phone-input .PhoneInputCountryIconImg {
  width: 20px;
  height: 20px;
}

.custom-phone-input .PhoneInputInput {
  border: none;
  outline: none;
}

.custom-phone-input .form-control {
  width: 100%;
}
