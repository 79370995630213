.menu-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.post-menu-images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.menu-actions .ant-btn {
  margin-right: 10px;
}

.relative {
  position: relative;
}

.overflow-x-auto {
  overflow-x: auto;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.dark .bg-gray-800 {
  background-color: #1a202c;
}

.dark .text-white {
  color: #ffffff;
}

.rounded-alg {
  border-radius: 8px;
}

.border-orange-300 {
  border-color: #f6ad55;
}

.bg-gray-200 {
  background-color: #edf2f7;
}

.text-yellow-500 {
  color: #ecc94b;
}
