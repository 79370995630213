@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  transition: background-color 0.3s, color 0.3s;
}

:root {
  font-family: "Poppins", sans-serif;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
