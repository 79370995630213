body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #f8f8f8;
}

.home,
.menu {
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  background-color: #001529;
  color: white;
  padding: 0 20px;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}
