/* Default styling */
#LocationSelector .form-group .location-btn-wrapper {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#LocationSelector .form-group .location-btn-wrapper .btn-location {
  display: inline-block;
  width: 45%;
}

/* Media query for small screens (e.g., tablets and below) */
@media (max-width: 768px) {
  #LocationSelector .form-group .location-btn-wrapper {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
  #LocationSelector .form-group .location-btn-wrapper .btn-location {
    width: 100%;
    margin-bottom: 10px; /* Optional: Add some spacing between buttons */
  }
}

/* Media query for very small screens (e.g., phones) */
@media (max-width: 480px) {
  #LocationSelector .form-group .location-btn-wrapper {
    width: 100%;
  }
  #LocationSelector .form-group .location-btn-wrapper .btn-location {
    width: 100%;
    margin-bottom: 8px; /* Optional: Adjust spacing for smaller screens */
    padding: 10px;
  }
}
