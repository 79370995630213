/* Center the login container */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

/* Style the login box */
.login-box {
  text-align: center;
  background: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.2);
  width: 350px;
}

/* Title for the login page */
.login-title {
  font-size: 24px;
  margin-bottom: 16px;
  color: #333;
}

/* Subtitle for the email field */
.login-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 8px;
}

/* Common style for all buttons */
.login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Primary continue button */
.primary {
  background-color: #1a73e8;
  color: white;
  margin-bottom: 20px;
}

/* Signup prompt style */
.signup-prompt {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

/* Style for 'Or' divider */
.or-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
  color: #666;
}

.or-divider::before,
.or-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ccc;
  margin: 0 10px;
}

/* Specific styles for Google button */
.google {
  background-color: #ffffff;
  color: #1a73e8;
  border: 1px solid #1a73e8;
}
.telegram {
  background-color: #ffffff;
  color: #1a73e8;
  border: 1px solid #1a73e8;
}
.signup-link {
  color: #4285f4;
}
.google:hover {
  background-color: #357ae8;
}

/* Specific styles for Microsoft button */
.microsoft {
  background-color: #2f2f2f;
  color: white;
}

.microsoft:hover {
  background-color: #1f1f1f;
}

/* Specific styles for Apple button */
.apple {
  background-color: #000;
  color: white;
}

.apple:hover {
  background-color: #333;
}

/* Style for icons */
.login-icon {
  margin-right: 10px;
  font-size: 18px;
}
